@mixin burgerLines {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: $red;
  transition: 0.2s;
}

.burger-menu {
  &.active {
    .bg {
      display: block;
    }
    .burger-menu__panel {
      left: 0;
    }
  }
  .bg {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100%;
    background: $black_50;
    top: 0;
    left: 0;
    z-index: 4;
  }

  &__button {
    position: relative;
    width: 40px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;
    &:hover {
      opacity: 0.7;
    }
    span {
      position: relative;
      display: block;
      height: 3px;
      width: 100%;
      background: $red;
      z-index: 5;
      &::after {
        @include burgerLines();
        top: -10px;
      }
      &::before {
        @include burgerLines();
        bottom: -10px;
      }
    }
    &.active {
      position: relative;
      span {
        position: relative;
        z-index: 11;
        background: $white;
        &::after {
          @include burgerLines();
          background-color: $white;
          top: 0;
          transform: rotate(45deg);
        }

        &::before {
          @include burgerLines();
          transform: rotate(-45deg);
          bottom: 0;
          background-color: $white;
        }
      }
    }
  }
  &__panel {
    position: fixed;
    width: 450px;
    background: $red;
    height: 100vh;
    left: -450px;
    z-index: 10;
    top: 0;
    padding: 60px 30px;
    transition: 0.2s;

    @media (max-width: 450px) {
      width: 100%;
    }
    .close {
      position: absolute;
      right: 30px;
      top: 15px;
      height: 40px;
      width: 40px;
      cursor: pointer;
      display: grid;
      place-items: center;
      transition: 0.2s;
      &::after {
        @include burgerLines();
        transform: rotate(45deg);
        background-color: $white;
      }
      &::before {
        @include burgerLines();
        transform: rotate(-45deg);
        background-color: $white;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    ul {
      list-style-type: none;
      li {
        margin-bottom: 25px;
        a {
          font-weight: 300;
          text-decoration: none;
          font-size: 20px;
          color: $white;
          transition: 0.2s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
