@mixin _600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin _800 {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin title {
  text-align: center;
  font-size: 36px;
  font-size: calc(22px + 14 * (100vw / 1280));
  text-transform: uppercase;
  margin-bottom: 100px;

  @include _600 {
    margin-bottom: 50px
  };
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}