.committeeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  text-align: center;

  .profile-photo {
    height: 200px;
    width: 200px;
    border-radius: 50%;
  }

  h3 {
    font-size: 22px;
    margin-top: 15px;
  }

  p.descr {
    font-size: 14px;
    color: $black_50;
    font-weight: 300;
    margin: 15px 0;
    white-space: pre-line;
  }

  span.location {
    font-weight: 300;
  }
}