@import "normalize";
@import "./variables";
@import "./components/all";
@import "./mixins";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
}

.wrapper {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

section {
  padding: 50px 0;
}

.header {
  display: block;
  &__top {
    position: relative;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 40px;
    @include _800() {
      height: 100px;
      padding: 0 15px;
    }

    .logoContainer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 85%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px
    }

    .logo {
      height: 100%;
    }

    .logo2 {
      height: 90%;
    }

    .social {
      a {
        margin-left: 5px;
      }
    }
  }

  &__main {
    position: relative;
    height: 660px;
    background: -webkit-linear-gradient($red, $black);
    text-align: center;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include _800() {
      height: 60vh;
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    &-bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url("../assets/img/main.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 70%;
      opacity: 0.3;
      z-index: 1;
    }

    h1.title {
      color: $white;
      font-size: 50px;
      padding-top: 50px;
      font-size: calc(24px + 26 * (100vw / 1280));
      @include _600() {
        padding-top: 25px;
      }
    }
    h2.title {
      font-size: calc(16px + 10 * (100vw / 1280));
      letter-spacing: 2.5px;
    }
    h1.title,
    h2.title {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

#info {
  // max-width: 960px;
  text-align: center;
  margin: 0 auto;
  padding: 45px 10px;

  ul {
    list-style-type: none;
    h1 {
      @include title;
      color: black;
    }
    li {
      margin: 30px 0;
      &:first-child {
        margin-top: 0;
      }
      a {
        font-weight: 200;
        font-size: 24px;
        color: black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        b {
          color: $red;
        }
      }
    }
  }

  h1 {
    text-transform: uppercase;
    color: $red;
    line-height: 1.23;
    margin-bottom: 40px;
    @include _800() {
      font-size: 25px;
    }
  }

  p {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
    @include _800() {
      font-size: 20px;
    }
    @include _600() {
      font-size: 16px;
    }

    span {
      color: $red;
    }
  }

  .organizers,
  .so-organizers {
    h1 {
      @include title;
      margin-top: 50px;
      color: black;
    }
  }

  .organizers {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 100px;

    span {
      display: block;
      font-size: 18px;
      padding-top: 16px;
    }
  }

  .so-organizers {
    &__container {
      max-width: 800px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      justify-items: center;
    }

    &__item {
      width: fit-content;
      max-width: 250px;
      margin-bottom: 50px;
      p {
        font-size: 16px;
        margin: 10px 0;
      }
      span {
        font-weight: 400;
      }
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}

#committee {
  .wrapper {
    h1 {
      @include title;
    }
    .committee-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 360px));
      justify-content: center;
      justify-items: center;
      column-gap: 30px;
      row-gap: 100px;
    }
  }
}

#sections {
  .wrapper {
    @include flex-column-center();
  }

  h1 {
    @include title;
    color: $red;
  }

  .info {
    width: 100%;
    @media (max-width: 1100px) {
      margin-top: 70px;
    }
    p {
      font-weight: 300;
      line-height: 1.5;
      font-size: 16px;
    }
  }

  h3 {
    margin-bottom: 100px;
    font-size: 20px;
  }

  .sections__block {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: center;
    justify-items: center;
    // margin-bottom: 50px;

    h2 {
      line-height: 1.35;

      @include _800() {
        font-size: 20px;
      }

      @include _600() {
        font-size: 15px;
      }
    }

    .designation {
      @include flex-column-center();
      grid-column: 2/3;
      min-height: 150px;
      margin: 0 20px;
      @include _800() {
        min-height: 100px;
        // height: 100%;
      }

      .number {
        min-height: 40px;
        min-width: 50px;
        font-size: 20px;
        padding: 4px 10px;
        color: $white;
        background: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        // border-radius: 50%;
        @include _600() {
          min-width: 30px;
          min-height: 30px;
          font-size: 16px;
        }
      }

      .line {
        width: 2px;
        height: 100%;
        background: $red;
      }
    }
  }
}

#deadline {
  .wrapper {
    @include flex-column-center();
    h1 {
      @include title;
    }
    h3 {
      font-size: 20px;
      margin-top: 20px;
      text-align: center;
    }
    .container {
      max-width: 700px;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      .calendar {
        min-width: 20px;
        max-width: 80px;
        fill: $red;
      }

      .right-side {
        margin-left: 20px;
        max-width: 700px;
        margin-bottom: 50px;
        h4 {
          font-size: 20px;
          margin-bottom: 20px;
          @include _600() {
            font-size: 18px;
          }
        }
        p {
          font-weight: 300;
          font-size: 18px;
          line-height: 1.55;
          margin-bottom: 30px;
          border-bottom: 1px solid rgba($red, 0.2);
          padding-bottom: 20px;

          &:last-child {
            border: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }
          @include _600() {
            font-size: 15px;
          }
          a {
            color: $red;
          }
          span.bold {
            font-weight: 600;
          }
          span.red {
            color: $red;
          }
        }
      }
    }
  }
}

#requirements {
  .wrapper {
    padding: 0 10vw;
    font-size: 18px;
    font-weight: 300;

    @include _600 {
      padding: 0 15px;
    }

    h1 {
      @include title();
    }
    p {
      line-height: 1.55;
    }

    .theses {
      margin-top: 100px;
      a {
        color: $red;
        font-size: 70%;

        &:hover {
          opacity: 0.7;
        }
      }
      ol {
        padding: 0 10px;
        li {
          margin-bottom: 20px;
        }
      }
      ul {
        margin-top: 20px;
        margin-left: 20px;
        li {
          margin-bottom: 5px;
        }
      }
    }

    .example-link {
      color: $red;
      display: block;
      margin-top: 15px;
      transition: opacity 0.3s;

      &:visited {
        color: $red;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

#past-conferences {
  h1 {
    @include title();
  }
  .info {
    margin-bottom: 100px;
    p {
      font-weight: 300;
      line-height: 1.5;
      font-size: 16px;
    }
  }
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    gap: 30px;
    .conference-item {
      display: flex;
      align-items: flex-start;
      .number {
        font-size: 22px;
        font-weight: 600;
        min-height: 50px;
        min-width: 50px;
        background: $black_10;
        display: grid;
        place-items: center;
        border-radius: 50%;
      }
      .content {
        margin-left: 20px;
        h2 {
          margin-bottom: 20px;
        }
        a {
          text-decoration: none;
          letter-spacing: 1.5px;
          font-weight: 300;
          color: $red;
          text-transform: uppercase;
          line-height: 1.7;
          @include _600() {
            font-size: 15px;
            letter-spacing: 1.2px;
            line-height: 1.4;
          }
        }
      }
    }
  }
}

#contacts {
  position: relative;
  text-align: center;
  // max-width: 1000px;
  margin: 0 auto;
  h1 {
    @include title();
  }
  p {
    padding: 0 15px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.7;
    @include _600 {
      font-size: 18px;
    }
    a {
      color: $red;
    }
  }

  .oferta {
    color: $red;
    transition: opacity .4s;

    &:visited {
      color: $red;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

#gallery {
  .wrapper {
    h1 {
      @include title();
    }
    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      // height: 90vh;
      width: 100%;

      .btns {
        margin-top: 20px;
        & > * {
          position: relative;
          z-index: 2;
          width: 30px;
          height: auto;
          margin: 0 10px;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      img {
        max-height: 70vh;
        // min-height: 400px;
        max-width: 95vw;
        position: absolute;
        transition: opacity 0.5s;
        z-index: 1;
        // visibility: hidden;
        opacity: 0;
        // display: none;
        height: 100%;

        &.active {
          display: block;
          position: relative;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
