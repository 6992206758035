@import "../variables";

.accordion {
  width: 100%;
  margin-bottom: 20px;
  &__button {
    position: relative;
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    padding-right: 40px;
    &::after {
      content: "+";
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 30px;
    }
    &.active::after {
      content: "-";
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 40px;
    }
  }
  .active,
  .accordion:hover {
    background-color: #ccc;
  }

  &__panel {
    padding: 20px 18px;
    background-color: $black_10;
    display: none;
    overflow: hidden;
    white-space: pre-line;
  }
}
